import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Container } from '@emburse/embark-core';
import { EmburseThemeProvider } from '@emburse/embark';

import { BreadcrumbsProvider } from '../Navigation/BreadcrumbsProvider';
import { ErrorHandlerProvider } from '../Common/ErrorHandler';
import { Navigation } from '../Navigation';

import { designToken } from '../../designToken';
import './App.css';
import {
  routesForUsersInCompany,
  routesForExpensesInCompany,
  routesForCardProgramsInCompany,
  routesForCreditsInCompany,
  routesForDebitsInCompany,
  routesForCompany,
  routesForCompanyRequests,
  routesForMainView,
  mainViewRoute,
  routesForSettings
} from '../../api/route_paths';
import { MainViews } from '../Navigation/MainViews';
import { SettingsViews } from '../Navigation/SettingsViews';

export const allRoutes = [
  mainViewRoute,
  ...routesForMainView,
  ...routesForCompany,
  ...routesForUsersInCompany,
  ...routesForExpensesInCompany,
  ...routesForCardProgramsInCompany,
  ...routesForCreditsInCompany,
  ...routesForDebitsInCompany,
  ...routesForCompanyRequests
];

const companiesRoutes = [
  ...routesForCompany,
  ...routesForUsersInCompany,
  ...routesForExpensesInCompany,
  ...routesForCardProgramsInCompany,
  ...routesForCreditsInCompany,
  ...routesForDebitsInCompany
]

const settingsRoutes = [
  ...routesForSettings
]

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, error, logout } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div>
        <div>Loading ...</div>
        <button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>
      </div>
    );
  }

  if (!isAuthenticated) {
    const urlParams = new URLSearchParams(window.location.search);
    const loginOptions = {
      invitation: urlParams.get('invitation') || undefined,
      organization: urlParams.get('organization') || undefined,
      organization_name: urlParams.get('organization_name') || undefined
    };

    loginWithRedirect(loginOptions);
    return <></>;
  }

  return (
    <EmburseThemeProvider designToken={designToken}>
      <Router>
        <Navigation />
        <BreadcrumbsProvider>
          <Container classes={{ root: 'app-wrapper' }}>
            <ErrorHandlerProvider>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/companies" />
                </Route>
                <Route path='/companies/:subroute'>
                  <Switch>
                    {companiesRoutes.map(({ path, Component, exact }) => (
                      <Route key={path} path={path} exact={exact}>
                        <Component />
                      </Route>
                    ))}
                  </Switch>
                </Route>
                <Route path="/settings/:section">
                  <Switch>
                    {settingsRoutes.map(({ path, Component, exact }) => (
                      <Route key={path} path={path} exact={exact}>
                        <Component />
                      </Route>
                    ))}
                  </Switch>
                </Route>

                <Route path='/company_requests'>
                  <Switch>
                    {routesForCompanyRequests.map(({ path, Component, exact }) => (
                      <Route key={path} path={path} exact={exact}>
                        <Component />
                      </Route>
                    ))}
                  </Switch>
                </Route>
                <Route path='/settings'>
                  <SettingsViews />
                </Route>
                <Route path='/'>
                  <MainViews />
                </Route>
              </Switch>
            </ErrorHandlerProvider>
          </Container>
        </BreadcrumbsProvider>
      </Router>
    </EmburseThemeProvider>
  );
};

export { App };

import { TextField, Typography, Button } from '@emburse/embark';

import { Spacer } from '../Common/UI';
import { getSPMetadata, getSVBSSOSettings } from '../../api/routes/svb_sso';
import { useApi } from '../../hooks/useApi';
import { GetAuthorizedApiService } from '../../api';
import { useError } from '../Common/ErrorHandler';

export type SVBSSOConfigResponse = {
  public_key: string;
  idp_issuer: string;
  legacy_public_key: string;
  public_key_expiry: string;
  legacy_key_expiry: string;
};

export const SSOSettings = () => {
  const { item, loading } = useApi<SVBSSOConfigResponse>(getSVBSSOSettings());
  const { showError } = useError();
  
  const { makeRawRequest } = GetAuthorizedApiService()

  const downloadSPMetadata = async () => {
    try {
      const response = await makeRawRequest(getSPMetadata(), '')
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'spmetadata.xml';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      showError({
        status: 500,
        statusText: "Request Failed",
        message: "Failed to download metadata file"
      })
    }
  };

  return (
    <>
      <Typography variant="h4">
        SVB Unified SSO Settings
      </Typography>
      <Button onClick={downloadSPMetadata}>Download SP Metadata File</Button>
      {loading ? "Fetching data, please wait..." : (
<>
      <Typography variant='body1'>
        Issuer: 
      </Typography>
      <TextField id="idp_issuer" value={item?.idp_issuer || "error fetching data"}  disabled={true} fullWidth={true}/>
      <Spacer />
      <Typography variant='body1'>
        Public Key: 
      </Typography>
      <TextField  id="public_key"  value={item?.public_key || "error fetching data"} rows={5} disabled={true} multiline={true} fullWidth={true} />
      <Typography variant='body1'>
        Expires: {item?.public_key_expiry}
      </Typography>
      <Spacer />
      <Typography variant='body1'>
        Legacy Key: 
      </Typography>
      <TextField  id="legacy_public_key" value={item?.legacy_public_key || "error fetching data"}  rows={5}  disabled={true} multiline={true} fullWidth={true}/>
      <Typography variant='body1'>
        Expires: {item?.legacy_key_expiry}
      </Typography>
      <Spacer />
      </>
      )}
    </>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import { IPostRequestOptions, IPutRequestOptions, IRequestOptionsWithBody, WriteRequestOptionsType } from './IRequestTypes';
import { useError } from '../../components/Common/ErrorHandler';

export interface IAuthorizedApiService {
  makePutRequest: <T>(route: string, payload: IRequestOptionsWithBody) => Promise<T>;
  makeDeleteRequest: <T>(route: string) => Promise<T>;
  makeGetRequest: <T>(route: string) => Promise<T>;
  makePostRequest: <T>(route: string, payload?: IRequestOptionsWithBody) => Promise<T>;
  makeRawRequest: (route: string, query: string, payload?: WriteRequestOptionsType) => Promise<Response>
}

export function GetAuthorizedApiService(): IAuthorizedApiService {
  const { showError } = useError();

  const { getAccessTokenSilently } = useAuth0();

  async function makeRawRequest(route: string, query = '', payload?: WriteRequestOptionsType) {
    const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
    return callApiRaw(token, route, query, payload);
  }

  async function makeRequest<T>(route: string, query = '', payload?: WriteRequestOptionsType): Promise<T> {
    try {
      const token = await getAccessTokenSilently({ audience: process.env.REACT_APP_AUTH0_AUDIENCE });
      return await callApi(token, route, query, payload);
    } catch (e) {
      showError(e, route.split('?')[0]);
      throw e;
    }
  }

  async function makePutRequest<T>(route: string, payload: IRequestOptionsWithBody): Promise<T> {
    const requestOptions: IPutRequestOptions = {
      ...payload,
      method: 'PUT'
    };

    return await makeRequest<T>(route, undefined, requestOptions);
  }

  async function makeDeleteRequest<T>(route: string): Promise<T> {
    return await makeRequest<T>(route, undefined, { method: 'DELETE' });
  }

  async function makeGetRequest<T>(route: string, query = ''): Promise<T> {
    return await makeRequest<T>(route, query);
  }

  async function makePostRequest<T>(route: string, payload?: IRequestOptionsWithBody): Promise<T> {
    const requestOptions: IPostRequestOptions = {
      ...payload,
      method: 'POST'
    };

    return await makeRequest<T>(route, undefined, requestOptions);
  }

  return { makePutRequest, makeDeleteRequest, makeGetRequest, makePostRequest, makeRawRequest };
}

export async function callApi(token: string, route: string, query = '', fetchOptions?: WriteRequestOptionsType) {
  let response: any;

  try {
    response = await callApiRaw(token, route, query, fetchOptions);
  } catch (e) {
    throw {
      statusText: 'Networking error',
      message: e.message
    };
  }

  const data = await response.json();
  if (response.ok) {
    // TODO: add return types
    return data;
  }

  throw {
    statusText: response.statusText,
    status: response.status,
    message: data.error
  };
}

export async function callApiRaw(token: string, route: string, query = '', fetchOptions?: WriteRequestOptionsType) {
  const url = `${process.env.REACT_APP_API_ENDPOINT}${route}${query}`;

  const options: RequestInit = {
    ...fetchOptions,
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    }
  };

  return fetch(url, options);
}
import { Tabs } from '@emburse/embark';

import { useTabs } from '../../hooks';
import { Route, Switch } from 'react-router-dom';
import { routesForSettings } from '../../api/route_paths';

const mainTabs = [
  { label: 'SSO Settings', uri: 'settings' }
];

function SettingsViews() {
  const { tabValue, onLinkedTabChange } = useTabs(mainTabs);

  return (
    <>
      <Tabs id="main-view-tabs" key={tabValue} variant="scrollable" tabs={mainTabs} initialIndex={tabValue} onChange={onLinkedTabChange} />

      <Switch>
        {routesForSettings.map(({ path, Component, exact }) => (
          <Route key={path} path={path} exact={exact}>
            <Component />
          </Route>
        ))}
      </Switch>
    </>
  );
}

export { SettingsViews };
